import {Injectable} from '@angular/core';
import {HttpBackend, HttpClient} from '@angular/common/http';
import {environment, globalAppParam} from 'src/environments/environment';
import {Year} from 'src/app/model/user/year.model';
import {MedicalQuestionnaire} from 'src/app/model/user/medical-questionnaire.model';
import {Observable} from 'rxjs';
import {FileInfoDTO} from 'src/app/model/dto/file-info.dto';
import {AttestationDTO} from '../../model/dto/attestation.dto';
import {isNotNullOrUndefined} from '../../utils/utils.static';
import {AbstractWebservice} from "./abstract.webservice";

@Injectable({
  providedIn: 'root'
})
export class YearWebservice extends AbstractWebservice {
  entity = `years`;

  constructor(protected readonly httpClient: HttpClient,
              protected readonly httpBackend: HttpBackend) {
    super(httpClient, httpBackend);
  }

  postUpdateCertificat(idUser: number, formData: FormData): Observable<FileInfoDTO[]> {
    return this.post<FileInfoDTO[]>(`${this.root}/certif/${idUser}`, formData);
  }

  delRemoveCetificat(idYear: number) {
    return this.delete(`${this.root}/certif/${idYear}`);
  }

  postUpdateYearSport(year: Year) {
    return this.post(`${this.root}/update-sport`, year);
  }

  getAllSdlvDocsByUserId(idUser: number) {
    return this.get(`${this.root}/docs/sdlv/${idUser}`);
  }

  getUserDoc(idUser: number, docName: string): Observable<FileInfoDTO> {
    return this.get<FileInfoDTO>(`${this.root}/doc/${idUser}?fileName=${docName}`);
  }

  postValidationYearSport(idYoung: number) {
    return this.post(`${this.root}/validationSport`, idYoung);
  }

  postCancelYear(idYoung: number) {
    return this.post(`${this.root}/cancel-validation`, idYoung);
  }

  getCurrentYear(): number {
    let limitYear: number = new Date().getFullYear();
    if (new Date() < globalAppParam.limitDateInscription) {
      limitYear--;
    }

    return limitYear;
  }

  checkLastCertificate(id: number) {
    return this.get(`${this.root}/checkLastCertificat/${id}`);
  }

  acceptEngagementChart(idYear: number) {
    return this.post(`${this.root}/engagementChart`, idYear);
  }
}
